import React, { useState } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import arrow from "../images/arrow.svg"

const FaqItem = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  .content {
    padding: 20px 10px 0 10px;
    /* p {
      margin: 0;
    } */
  }
  h3 {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    button {
      margin: 0;
      padding: 9px 11px;
      background: none;
      border: 1px solid #1a1919;
      display: block;
      cursor: pointer;
      width: 100%;
      text-align: left;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    span {
      color: #000;
    }
    img {
      margin: 0 0 0 5px;
      transform: rotate(90deg);
      &.opened {
        transform: rotate(-90deg);
      }
    }
  }
  @media (max-width: 568px) {
    h3 {
      font-size: 16px;
      button {
        padding: 6px 10px;
      }
    }
    .content {
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
    }
  }
`

function FaqComponent({ data }) {
  const [opened, setOpen] = useState(false)
  //   const value = documentToReactComponents(data.body.raw)
  return (
    <FaqItem id={data.anchor}>
      <h3>
        <button
          onClick={() => {
            setOpen(!opened)
          }}
          onKeyDown={() => {
            setOpen(!opened)
          }}
        >
          <span>{data.title}</span>
          <img
            className={opened ? "opened" : ""}
            src={arrow}
            alt={data.title}
            width="16"
            height="16"
          />
        </button>
      </h3>
      {opened && <div className="content">{renderRichText(data.body)}</div>}
    </FaqItem>
  )
}

export default FaqComponent
