import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FaqItem from "../components/FAQ"
import Triangle from "../components/styles/Triangle"
import Title from "../components/styles/Title"

const FaqWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  padding: 40px 25px;
  border: 1px solid #1a1919;
  @media (max-width: 568px) {
    padding: 20px 15px;
  }
`
const FaqSection = styled.div`
  margin-bottom: 32px;
  h2 {
    font-size: 32px;
    color: #0a0908;
    margin-bottom: 16px;
  }
  @media (max-width: 568px) {
    margin-bottom: 15px;
    h2 { 
      font-size: 24px;
    }
  }
`

function FAQPage({ data }){
  const faqCategories = data.allContentfulFaq.edges[0].node.faqCategories;
  return (
    <Layout>
      <Seo title="FAQ | komm schon Alter" descripion='General information about our events: tickets, location, house rules, sustainability & more' keywords='Informatie, Das Festival, club night, Amsterdam, house, techno, mature, tickets, ADE, line-up' />
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Title>FAQ</Title>
        <FaqWrapper>
          {faqCategories.map((node, i) => (
            <FaqSection key={i}>
              <h2>{node.title}</h2>
              {node.items && node.items.map((item, j) => (
                <FaqItem key={j} data={item}></FaqItem>
              ))}
            </FaqSection>
          ))}
        </FaqWrapper>
      </div>
      <Triangle color="#ffc498"></Triangle>
    </Layout>
  )
}
export const query = graphql`
  query {
    allContentfulFaq {
      edges {
        node {
          faqCategories {
            title
            items {
              title
              anchor
              body {
                raw
              }
            }
          }
        }
      }
    }
  }
`

export default FAQPage
